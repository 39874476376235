export default function SupportPage() {
  return (
    <div className="bg-white flex h-screen items-center justify-center text-center">
      <div>
        <h1 className="text-4xl font-bold">Support</h1>
        <p className="mt-4 text-lg">
          <a
            href="mailto:support@trybuddytext.com"
            className="text-orange-600 hover:text-orange-800"
          >
            Email support@trybuddytext.com →
          </a>
        </p>
      </div>
    </div>
  );
}
