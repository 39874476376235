const navigation = {
  main: [
    { name: "Support", href: "support" },
    { name: "FAQ", href: "faq" },
  ],
};

export default function Footer() {
  return (
    <footer className="bg-black">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-10 sm:py-12 lg:px-8 flex items-center justify-between">
        <a
          href="/"
          className="-m-1.5 p-1.5"
          style={{ fontFamily: "'Grand Hotel', cursive" }}
        >
          <span className="sr-only">BuddyText</span>
          <span className="text-3xl text-orange-600">BuddyText</span>
        </a>
        <div className="flex lg:gap-x-12">
          {navigation.main.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-lg font-semibold leading-6 text-white hover:text-gray-300"
            >
              {item.name}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
}
