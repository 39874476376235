import { PopupButton } from "@typeform/embed-react";
import {
  ChatBubbleBottomCenterIcon,
  ChartBarIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import demoImage from "./demo.jpg";

const accountabilityFeatures = [
  {
    name: "Your Accountability Buddy",
    description:
      "Connect with a real person who texts you regularly to keep you focused and on track.",
    icon: ChatBubbleBottomCenterIcon,
  },
  {
    name: "Track Your Goals",
    description:
      "Get weekly reports summarizing your progress and see how you're doing over time.",
    icon: ChartBarIcon,
  },
  {
    name: "Privacy Matters",
    description:
      "We take your privacy seriously. Your data is safe with us - no selling, no ads.",
    icon: ShieldCheckIcon,
  },
];

export default function LandingPage() {
  return (
    <div className="bg-white">
      <main>
        {/* Hero section */}
        <div className="relative isolate">
          <div className="mx-auto max-w-7xl px-6 py-4 sm:py-12 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-20">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
              <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Daily texts from your accountability buddy
              </h1>
              <div className="mt-8">
                <PopupButton id="ioSM3Xs9" className="coolButton">
                  Get Started
                </PopupButton>
                <p className="mt-2 text-sm text-gray-600">
                  No credit card required. Opt out anytime.
                </p>
              </div>
            </div>
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
              <svg
                viewBox="0 0 366 729"
                role="img"
                className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
              >
                <title>App screenshot</title>
                <defs>
                  <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                    <rect width={316} height={684} rx={36} />
                  </clipPath>
                </defs>
                <path
                  fill="#4B5563"
                  d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                />
                <path
                  fill="#343E4E"
                  d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                />
                <foreignObject
                  width={316}
                  height={684}
                  transform="translate(24 24)"
                  clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                >
                  <img src={demoImage} alt="" />
                </foreignObject>
              </svg>
            </div>
          </div>
        </div>

        {/* Feature section */}
        <div className="mx-auto my-16 max-w-7xl px-6 sm:my-28 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-2xl font-semibold leading-7 text-orange-600">
              Real Support, Real Progress
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Your Personal Accountability Partner in Texts
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Get tailored support via text messaging to keep you focused and
              moving towards your goals.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {accountabilityFeatures.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-2xl font-semibold leading-7 text-gray-900">
                    <feature.icon
                      className="h-5 w-5 flex-none text-orange-600"
                      aria-hidden="true"
                    />
                    {feature.name}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-lg leading-7 text-gray-600">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        {/* CTA section */}
        <div className="relative isolate overflow-hidden  mt-16 sm:mt-28 bg-gray-900">
          <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Stay Accountable
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                Connect with a real person for daily text support and guidance.
                Make your goals a reality and know you can easily opt out
                anytime.
              </p>
              <PopupButton
                id="ioSM3Xs9"
                className="coolButton"
                style={{ marginTop: "2rem" }}
              >
                Get Started
              </PopupButton>
            </div>
          </div>
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#8d958450-c69f-4251-94bc-4e091a323369)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
                <stop stopColor="#FFB347" />
                <stop offset={1} stopColor="#FF8300" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </main>
    </div>
  );
}
