import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import LandingPage from "./landing/LandingPage";
import FAQPage from "./landing/faq/FAQPage";
import SupportPage from "./landing/support/SupportPage";

import Navbar from "./shared/Navbar";
import Footer from "./shared/Footer";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/support" element={<SupportPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
