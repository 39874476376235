const navigation = [
  { name: "Support", href: "support" },
  { name: "FAQ", href: "faq" },
];

export default function Navbar() {
  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <a href="/" className="-m-1.5 p-1.5" style={{ fontFamily: "'Grand Hotel', cursive" }}>
          <span className="sr-only">BuddyText</span>
          <span className="text-3xl text-orange-600">BuddyText</span>
        </a>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-lg font-semibold leading-6 text-gray-900 hover:text-gray-600"
            >
              {item.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
  );
}
