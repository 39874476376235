const faqs = [
  {
    id: 1,
    question: "What exactly does BuddyText offer?",
    answer:
      "BuddyText pairs you with a personal accountability partner who provides daily SMS support to help you stay focused on and achieve your goals. This service includes daily motivation, goal tracking reminders, and personal support.",
  },
  {
    id: 2,
    question: "Do I need to install an app for BuddyText?",
    answer:
      "No, there's no app to download. BuddyText operates entirely through SMS, making it accessible on any phone with text messaging capabilities.",
  },
  {
    id: 3,
    question: "What is your business model?",
    answer:
      "BuddyText is a subscription-based service. We offer various plans to suit different needs, but we guarantee that your data is never sold and you won't see ads.",
  },
  {
    id: 4,
    question: "Can I select who my accountability buddy will be?",
    answer:
      "As of now, we assign buddies based on compatibility with your goals. Our team ensures each buddy is qualified and aligned with your objectives. The option to choose your own buddy may become available in the future.",
  },
  {
    id: 5,
    question: "How secure is my information with BuddyText?",
    answer:
      "We prioritize your privacy. All personal information is securely stored and confidential. We uphold strict data protection policies and never share your information with third parties.",
  },
  {
    id: 6,
    question: "Is BuddyText powered by AI, like ChatGPT?",
    answer:
      "No, BuddyText does not utilize ChatGPT or similar AI technologies. Our service is based on real human interactions for personalized support.",
  },
  {
    id: 7,
    question: "What is your cancellation policy?",
    answer:
      "You're free to cancel your subscription at any time without hassle. Simply text or email us at support@trybuddytext.com. Your feedback is also valuable to us for improving our service.",
  },
  {
    id: 8,
    question: "Where can I get more information?",
    answer:
      "For more queries, feel free to reach out to us at support@trybuddytext.com. We're here to assist you!",
  },
];

export default function FAQPage() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl divide-y divide-gray-900/10 px-6 py-12 sm:py-32 lg:px-8 lg:py-20">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
          Frequently asked questions
        </h2>
        <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
          {faqs.map((faq) => (
            <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                {faq.question}
              </dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">
                  {faq.answer}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
